// @flow
import { type JSSThemeDefaultHeaderMobileType } from '../JSSThemeDefaultHeaderMobile';

export const JSSThemeHeaderMobile61900: JSSThemeDefaultHeaderMobileType = (
  themeVars
) => {
  const {
    colors: {
      colorTypes: { background4, text2 },
    },
  } = themeVars;
  return {
    HeaderMobile: {
      headerContainer: {
        background: background4,
      },
      iconContainer: {
        color: text2,
      },
      menuContainer: {
        background: background4,
      },
      logoImg: {
        width: 'auto',
        height: 50,
        margin: 3,
      },
      logoWrapper: {
        display: 'flex',
        alignItems: 'center',
        padding: 5,
      },
    },
  };
};
