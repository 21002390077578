// @flow
import { type JSSThemeDefaultFormType } from '../JSSThemeDefaultForm';

export const JSSThemeForm61900: JSSThemeDefaultFormType = (themeVars) => {
  const {
    colors: {
      colorTypes: { text2, white, background2, background7, text3 },
    },
    borderRadius,
  } = themeVars;
  return {
    Input: {
      root: {
        borderRadius: borderRadius.sm,
        border: 'none',
        background: '#4E5161',
        color: white,
        opacity: 0.7,
        '@media (pointer: coarse)': {
          background: '#4E5161',
          '&::placeholder': {
            color: background2,
          },
        },

        '&::placeholder': {
          color: background7,
        },
        '&:-webkit-autofill': {
          '-webkit-box-shadow': `0 0 0 30px #4E5161 inset !important`,
          '-webkit-text-fill-color': `${text2} !important`,
        },
      },
      betslipInput: {
        border: 'none',
        borderRadius: '2px',
        background: '#88D2B5',
        opacity: 1,
        fontSize: '12px !important',
        '&:-webkit-autofill': {
          '-webkit-box-shadow': `0 0 0 30px #88D2B5 inset !important`,
          borderColor: `#88D2B5 !important`,
          '-webkit-text-fill-color': `#1a1a1a !important`,
          fontSize: '12px !important'
        },
        '&::placeholder': {
          color: text3,
        },
      },
    },
    PasswordField: {
      iconShowPassword: {
        color: white,
      },
    },
  };
};
