// @flow
import { type JSSThemeDefaultCasinoType } from '../JSSThemeDefaultCasino';
import { applyOnlyMobile, applyOnlyDesktop } from '../jss-helpers';

export const JSSThemeCasino61900: JSSThemeDefaultCasinoType = (themeVars) => {
  const {
    margins,
    fontSizes,
    colors: {
      colorTypes: {
        white,
        neutral1,
        text5,
        background3,
        text4,
        background5,
        primary,
      },
    },
  } = themeVars;
  return {
    CasinoGameCategories: {
      gameCategories: {
        background: background5,
        marginTop: margins.xs,
        height: 'auto',
        minHeight: 60,
        position: 'static',
        overflowX: 'auto',
        '&::-webkit-scrollbar': {
          display: 'block',
          height: 15,
          background: '#0c101b',
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#626673',
        },
        '&::-webkit-scrollbar-button:horizontal': {
          backgroundRepeat: 'no-repeat',
          backgroundSize: '50%',
          backgroundPosition: 'center',
        },
        '&::-webkit-scrollbar-button:horizontal:decrement': {
          backgroundImage:
            'url(https://offsidegaming.s3.amazonaws.com/image/subway_up-arrow.svg)',
        },
        '&::-webkit-scrollbar-button:horizontal:increment': {
          backgroundImage:
            'url(https://offsidegaming.s3.amazonaws.com/image/subway_up-arrow%20%281%29.svg)',
        },
        '&::-webkit-scrollbar-button:horizontal:decrement:end': {
          display: 'none',
        },
        '&::-webkit-scrollbar-button:horizontal:increment:start': {
          display: 'none',
        },
        '&::-webkit-scrollbar-button:start:no-button': {
          backgroundSize: 'cover',
        },
      },

      menuItem: {
        color: white,
        borderRight: 'none',
        fontWeight: 'bold',
        transition: '.3s ease-in',
        '&.active, &:hover': {
          color: primary,
          background: 'transparent',
        },
        ...applyOnlyDesktop({
          '&:hover': {
            color: primary,
            background: 'transparent',
          },
        }),
        ...applyOnlyMobile({
          '&:hover, &:active': {
            color: primary,
          },
        }),
      },
      iconChevron: {
        '&.active': {
          transform: 'rotateZ(90deg) translateX(1px) translateY(-2px)',
        },
      },
    },
    SearchBox: {
      inputWrap: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 'auto',
      },
      inputSearch: {
        height: 32,
        border: '1px solid rgba(250,250,250,0.3)',
        borderRadius: 4,
        '&::placeholder': {
          opacity: 0.5,
        },
      },
      searchResult: {
        background: neutral1,
        padding: [0, margins.md],
      },
    },
    CasinoContainer: {
      providersFiltersWrap: {
        background: neutral1,
      },
      casinoTitleWrap: {
        '&>h1': {
          display: 'none',
        },
      },
    },
    CasinoGameLink: {
      rootReal: {
        fontWeight: 'bold',
        fontSize: fontSizes.md,
        boxShadow: '0 5px 15px #82C191',
        background: background3,
        '&:hover': {
          background: primary,
        },
      },
      rootFun: {
        fontSize: fontSizes.md,
        fontWeight: 'bold',
        background: white,
        color: text4,
        '&:hover': {
          background: text5,
          color: text4,
        },
      },
    },
  };
};
