// @flow
import { JSSThemeVariables } from '../JSSThemeDefault';

export const JSSThemeBonuses61900 = (themeVars: typeof JSSThemeVariables) => {
  const {
    margins,
    colors: {
      colorTypes: {
        white,
        background3,
        background5,
        text3,
        primary,
        background1,
      },
      colorCombinations: { whitePrimary },
    },
  } = themeVars;
  return {
    BonusesTabs: {
      tabsItemDesktop: {
        background: background3,
        color: white,
        transition: '.3s ease-in',
        '&:hover': {
          background: background1,
          color: white,
        },
        '&.active': {
          background: primary,
          boxShadow: '0 5px 15px #29b93a',
        },
      },
      tabsItemMobile: {
        color: white,
        background: background5,
        borderRight: 'none',
        fontWeight: 'bold',
        padding: margins.md,
        '&.active': whitePrimary,
      },
      tabsContentDesktop: {
        padding: [margins.md, 0],
      },
      bonusContainer: {
        background: 'transparent',
        border: 'none',
        padding: 0,
      },
    },
    Bonuses: {
      root: {
        border: 'none',
        borderRadius: 5,
        background: text3,
        '@media (pointer: coarse)': {
          background: background5,
        },
      },
    },
  };
};
