// @flow
import { type JSSThemeDefaultPromotionsType } from '../JSSThemeDefaultPromotions';

export const JSSThemePromotions61900: JSSThemeDefaultPromotionsType = (
  themeVars
) => {
  const {
    colors: {
      colorTypes: { background5 },
    },
  } = themeVars;
  return {
    SeasonalPromotion: {
      buttonRoot: {
        background: background5,
      },
    },
  };
};
