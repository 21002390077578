// @flow
import { type JSSThemeDefaultSportsMenuType } from '../JSSThemeDefaultSportsMenu';

export const JSSThemeSportsMenu61900: JSSThemeDefaultSportsMenuType = (
  themeVars
) => {
  const {
    fontSizes,
    margins,
    colors: {
      colorTypes: { background4, neutral1, white, primary, background5 },
    },
  } = themeVars;

  return {
    HorizontalSportsListUIBigIcons: {
      list: {
        background: background4,
      },
      sportLink: {
        padding: 0,
        color: neutral1,
      },
    },
    HorizontalSportsListUISmallIcons: {
      sportLink: {
        color: white,
        padding: [margins.xs, margins.xs, margins.xs, 0],
        fontWeight: 'bold',
      },
    },
    HorizontalSportsListIconSmall: {
      sportIcon: {
        fontSize: fontSizes.xl,
      },
    },
    HorizontalSportsListIconBig: {
      sportIcon: {
        color: white,
        '&.active': {
          color: primary,
        },
      },
      sportName: {
        fontWeight: 'bold',
        color: white,
        '&.active': {
          color: primary,
        },
      },
    },
    HorizontalLiveCasinoIcon: {
      icon: {
        color: background4,
        background: white,
      },
      title: {
        color: white,
      },
    },
    SportsMenuDesktop: {
      sport: {
        background: background5,
        '&.active, &:hover, &:focus': {
          background: primary,
        },
      },
      region: {
        background: 'transparent',
        padding: [margins.xs, margins.md, margins.xs, 34],
        fontSizes: fontSizes.xxl,
        '&.active, &:hover, &:focus': {
          background: primary,
        },
      },
      main: {
        background: background5,
        padding: [margins.lg, 0, margins.lg, 0],
        borderRadius: [0, 0, margins.md, margins.md],
      },
    },
  };
};
