// @flow
import { type JSSThemeDefaultHeaderType } from '../JSSThemeDefaultHeader';

export const JSSThemeHeader61900: JSSThemeDefaultHeaderType = (themeVars) => {
  const {
    margins,
    colors: {
      colorTypes: { background4 },
    },
  } = themeVars;
  return {
    HeaderDesktop: {
      root: {
        background: background4,
      },
      authContentWrap: {
        marginRight: margins.md,
      },
    },
    LogoBrand: {
      logo: {
        maxWidth: '200px',
      },
      logoLink: {
        height: '70px',
      },
    },
  };
};
