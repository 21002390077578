// @flow
import { LoadableComponentWrapper } from '../../components/components/LoadableComponentWrapper';
import { createKoltronApp } from '../createKoltronApp';
import {
  JSSTheme61900,
  JSSThemeVariables61900,
  getJSSThemePatterns61900,
} from './JSSTheme61900';
import { GeoblockPage61900 } from './GeoblockPage61900';
// $FlowIgnore
import '../../../styles/application-koltron.scss';

createKoltronApp({
  DesktopComponent: LoadableComponentWrapper({
    loader: async () => (await import('./FullDesktop61900')).FullDesktop61900,
  }),
  MobileComponent: LoadableComponentWrapper({
    loader: () =>
      import('./FullMobile61900').then((module) => module.FullMobile61900),
  }),
  JSSTheme: JSSTheme61900,
  JSSThemeVariables: JSSThemeVariables61900,
  JSSThemePatterns: getJSSThemePatterns61900,
  GeoblockPage: GeoblockPage61900,
});
