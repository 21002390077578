// @flow
import { type JSSThemeDefaultAffiliatesType } from '../JSSThemeDefaultAffiliates';

export const JSSThemeAffiliates61900: JSSThemeDefaultAffiliatesType = (
  themeVars
) => {
  const {
    margins,
    colors: {
      colorTypes: { neutral3, accent, background4, background5 },
    },
  } = themeVars;

  return {
    text: {
      margin: margins.md,
    },
    affiliatesHeading: {
      background: background4,
      margin: '0 auto',
      padding: '100px 20px 50px',
    },
    affiliatesHome: {
      topBanner: {
        background: background4,
      },
      step: {
        boxShadow: '0px 8px 21px 14px rgb(52 117 200 / 15%)',
      },
      affiliateRoot: {
        background: background4,
        minHeight: '100vh',
        height: 'auto',
        display: 'flex',
        flexDirection: 'column',
      },
      productsRoot: {
        background: background4,
      },
      productsHeading: {
        fontWeight: '800 !important',
      },
      topBannerButton: {
        background: '#709C76',
        transition: '.3s ease-in',
        boxShadow: '0 5px 15px #88d2b5',
        backgroundColor: '#82C191',
        backgroundImage:
          'radial-gradient(176.68% 684.2% at 100% 0%, #7CC9B1 0%, #83BF8C 100%, #82C191 100%)',
        '&:hover': {
          color: '#fff',
          background: '#82C191',
          backgroundColor: '#82C191',
          boxShadow: '0 5px 15px #82c191',
        },
      },
      productsButton: {
        background: '#709C76',
        transition: '.3s ease-in',
        boxShadow: '0 5px 15px #88d2b5',
        backgroundColor: '#82C191',
        backgroundImage:
          'radial-gradient(176.68% 684.2% at 100% 0%, #7CC9B1 0%, #83BF8C 100%, #82C191 100%)',
        '&:hover': {
          color: '#fff',
          background: '#82C191',
          backgroundColor: '#82C191',
          boxShadow: '0 5px 15px #82c191',
        },
      },
      topBannerRoot: {
        background: background4,
      },
    },
    affiliatesProducts: {
      productsButton: {
        background: '#709C76',
        transition: '.3s ease-in !important',
        boxShadow: '0 5px 15px #88d2b5',
        backgroundColor: '#82C191',
        backgroundImage:
          'radial-gradient(176.68% 684.2% at 100% 0%, #7CC9B1 0%, #83BF8C 100%, #82C191 100%)',
        '&:hover': {
          transition: '.3s ease-in !important',
          color: '#fff',
          background: '#82C191',
          backgroundColor: '#82C191',
          boxShadow: '0 5px 15px #82c191',
        },
      },
    },
    affiliatesContacts: {
      contactsMail: {
        '&:hover': {
          color: neutral3,
        },
      },
    },
    affiliateHeader: {
      logo: {
        height: 60,
        '@media (pointer: coarse)': {
          height: 35,
        },
      },
      headerRoot: {
        background: background4,
      },
      burgerOverlay: {
        background: background4,
      },
      topMenuItem: {
        color: '#fff !important',
        '&:hover': {
          color: `${neutral3} !important`,
        },
      },
      topMenuItemActive: {
        color: `${neutral3} !important`,
      },
      loginBarRoot: {
        background: background5,
        '@media (pointer: coarse)': {
          background: background4,
        },
      },
      SignupRoot: {
        color: '#fff',
        '&:hover': {
          color: '#fff',
          opacity: '.6',
        },
      },
      reasonsIcon: {
        fill: accent,
      },
    },
    affiliatesFooter: {
      productsTerms: {
        color: '#fff',
        '&:hover': {
          color: neutral3,
        },
      },
      footer: {
        background: background4,
        opacity: 1,
      },
      footerRoot: {
        background: background4,
        marginTop: 'auto',
      },
    },
  };
};
