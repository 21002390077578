// @flow
import { type JSSThemeDefaultOutcomesType } from '../JSSThemeDefaultOutcomes';

export const JSSThemeOutcomes61900: JSSThemeDefaultOutcomesType = (
  themeVars
) => {
  const {
    colors: {
      colorTypes: {
        accent,
        white,
        background1,
        background7,
        text3,
        success,
        primary,
      },
    },
  } = themeVars;
  return {
    MobileOutcome: {
      value1x2: {
        color: text3,
      },
      root: {
        background: background7,
        '&.active': {
          background: accent,
        },
      },
      priceValue: {
        color: text3,
        '&.active': {
          color: white,
        },
      },
    },
    Outcome: {
      root: {
        color: text3,
        background: background7,
        '@media not all and (pointer: coarse)': {
          '&:hover': {
            background: background1,
          },
        },
        '&.disabled': {
          color: text3,
          background: background7,
          '&:hover': {
            background: background1,
            color: white,
          },
        },
      },
      trendUp: {
        color: success,
      },
      trendDown: {
        color: primary,
      },
    },
  };
};
