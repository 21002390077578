// import { type JSSThemeDefaultFooterType } from '../JSSThemeDefaultFooter';
// @flow
import { type JSSThemeDefaultFooterAllType } from '../JSSThemeDefaultFooterAll';

export const JSSThemeFooterAll61900: JSSThemeDefaultFooterAllType = (
  themeVars
) => {
  const {
    colors: {
      colorTypes: { white, background5 },
    },
  } = themeVars;
  return {
    logoMain: {
      paddingBottom: 10,
    },
    footerWrap: {
      marginTop: '20px',
      background: background5,
      color: white,
    },
    socialHide: {
      display: 'block',
    },
    socialCont: {
      margin: '0 auto',
      paddingRight: 0,
    },
    socialWrap: {
      display: 'none',
    },
    newStyle: {
      display: 'flex',
      justifyContent: 'center',
      paddingBottom: 20,
      '@media screen and (max-width: 1300px)': {
        flexWrap: 'wrap',
      },
    },
    logosContainer: {
      display: 'flex',
      alignItems: 'center',
      margin: '10px 20px 10px 0',
    },
    legal: {
      borderTop: '1px solid rgba(255, 255, 255, 0.3)',
    },
    footerContainer: {
      padding: '10px 40px 80px',
    },
    line: {
      display: 'none',
    },
    menuWrapper: {
      paddingBottom: 0,
    },
  };
};
