// @flow
import { type JSSThemeDefaultAuthFormType } from '../JSSThemeDefaultAuthForm';

export const JSSThemeAuthForm61900: JSSThemeDefaultAuthFormType = (
  themeVars
) => {
  const {
    fontSizes,
    margins,
    colors: {
      colorTypes: { background3, background4, background5, white, primary },
    },
  } = themeVars;
  return {
    AuthFormDesktop: {
      depositWrap: {
        padding: [margins.xs, margins.md],
        background: primary,
        borderRadius: '4px',
        transition: '.3s ease-in',
        fontSize: fontSizes.sm,
        marginRight: margins.xs,
        boxShadow: '0 5px 15px #29b93a',
        '&:hover': {
          background: background3,
          boxShadow: '0 5px 15px #82C191',
        },
      },
    },
    DepositButton: {
      link: {
        fontWeight: 'bold',
        '&:hover': {
          opacity: '1',
        },
      },
      iconWrap: {
        marginRight: margins.md,
      },
    },
    LoginPage: {
      wrapper: {
        background: background4,
        padding: [margins.lg, margins.xl, margins.xl],
        borderRadius: 5,
        width: 400,
      },
      wrapperMobile: {
        background: background4,
      },
    },
    LoginFormDesktop: {
      popUpWrapper: {
        background: background5,
        '&:before': {
          background: background5,
        },
      },
      forgotText: {
        color: 'white',
      },
    },
    MobileAuthForm: {
      wrapper: {
        color: white,
      },
      link: {
        color: white,
      },
      label: {
        color: white,
      },
    },
  };
};
