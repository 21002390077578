// @flow
import { type JSSThemeDefaultLiveType } from '../JSSThemeDefaultLive';

export const JSSThemeLive61900: JSSThemeDefaultLiveType = (themeVars) => {
  const {
    colors: {
      colorTypes: { primary },
    },
  } = themeVars;
  return {
    LiveMenuEvent: {
      LiveEventRankedDesktop: {
        background: 'white !important',
        color: 'black !important',
      },
      TeamsText: {
        color: 'black !important',
        '@media (pointer: coarse)': {
          color: 'white !important',
        },
      },
      LiveIcon: {
        fill: primary,
      },
    },
    LiveStreamingMessage: {
      loginButton: {
        background:'#29b93a',
        transition: '.3s ease-in',
        '&:hover': {
          background:'#20c934',
        },
      },
    },
  };
};
