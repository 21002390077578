// @flow
import type { JSSThemeVariablesType } from '../JSSThemeDefault';

export const JSSThemeRegistration61900 = (themeVars: JSSThemeVariablesType) => {
  const {
    colors: {
      colorTypes: { white },
    },
  } = themeVars;
  return {
    RegistrationPageButton: {
      root: {
        background: '#29b93a',
        boxShadow: '0 5px 15px #29b93a',
        '&:hover': {
          color: white,
          background: '#29b93a',
          boxShadow: '0 5px 15px #82C191',
        },
      },
    },
  };
};
