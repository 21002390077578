// @flow
import { type JSSThemeDefaultPopularLeaguesType } from '../JSSThemeDefaultPopularLeagues';

export const JSSThemePopularLeagues61900: JSSThemeDefaultPopularLeaguesType = (
  themeVars
) => {
  const {
    colors: {
      colorTypes: { background5 },
    },
  } = themeVars;
  return {
    PopularLeagues: {
      root: {
        background: background5,
      },
    },
  };
};
